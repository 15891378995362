import React from 'react';
import { withRouter, Link as RouterLink } from 'react-router-dom';

import PropTypes from 'prop-types';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import Typography from '@material-ui/core/Typography';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import WorkIcon from '@material-ui/icons/Work';

import * as Yup from 'yup';

import { Formik, Field } from 'formik';
import { TextField } from 'formik-material-ui';

import { handleEndpointErrors, removeExtraSpaces } from '../shared/utilities';

import CustomFormikForm from '../components/CustomFormikForm';
import FormikAutocomplete from '../components/FormikAutocomplete';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0, 2, 3),
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(0, -2, 3),
    paddingLeft: theme.spacing(1),
    borderBottom: `1px solid ${ theme.palette.divider }`,
    '& .MuiTypography-root': {
      marginLeft: theme.spacing(2),
    },
  },
  token: {
    '& .MuiInputBase-input': {
      textTransform: 'uppercase',
    },
  },
  btnWrapper: {
    position: 'relative',
  },
  btnSubmit: {
    borderRadius: '40px',
    color: theme.palette.primary.contrastText,
    display: 'flex',
    margin: `40px auto 0`,
    minHeight: '36px',
    minWidth: '259px',
    '&.Mui-disabled': {
      backgroundColor: `${ theme.palette.text.disabled } !important`,
    },
  },
  btnProgress: {
    color: theme.palette.secondary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  modalTitle: {
    '& .MuiTypography-root ': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiSvgIcon-root': {
      marginLeft: theme.spacing(1),
    },
  },
}));

const Register = (props) => {
  const classes = useStyles();

  const [modalOpen, setModalOpen] = React.useState(false);
  const [username, setUsername] = React.useState('');

  const store = useStore();

  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const registerLoading = useStoreState(state => state.login.registerLoading);
  const functionalAreas = useStoreState(state => state.login.functionalAreas);
  const getFunctionalAreas = useStoreActions(actions => actions.login.getFunctionalAreas);
  const postRegister = useStoreActions(actions => actions.login.postRegister);

  const t = useTranslation();

  const handleModalClose = () => {
   setModalOpen(false);
   props.history.push({ pathname: '/login' });
  };

  return (
    <div className={ classes.root }>
      <div className={ classes.title }>
        <IconButton aria-label="back" component={ RouterLink } to="/">
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" component="h1">{ t('register.button') }</Typography>
      </div>
      <Formik
        initialValues={{ registrationToken: '', firstName: '', lastName: '', secondSurname: '', functionalArea: '', email: '', password: '', confirmPassword: '', phoneNumber: '' }}
        validationSchema={ Yup.object({
          registrationToken: Yup.string()
            .max(35, t('global.errors.max', { field: t('regiser.registrationToken'), length: 35 }))
            .required(t('global.errors.required', { field: t('register.registrationToken') })),
          firstName: Yup.string()
            .max(50, t('global.errors.max', { field: t('register.firstName'), length: 50 }))
            .required(t('global.errors.required', { field: t('register.firstName') })),
          lastName: Yup.string()
            .max(25, t('global.errors.max', { field: t('register.lastName'), length: 25 }))
            .required(t('global.errors.required', { field: t('register.lastName') })),
          secondSurname: Yup.string()
            .max(25, t('global.errors.max', { field: t('register.secondSurname'), length: 25 }))
            .required(t('global.errors.required', { field: t('register.secondSurname') })),
          functionalArea: Yup.object()
            .when([], {
              is: () => !!functionalAreas.length,
              then: Yup.object().required(t('global.errors.required', { field: t('register.functionalArea') })),
            }),
          email: Yup.string()
            .max(40, t('global.errors.max', { field: t('register.email'), length: 40 }))
            .email(t('global.errors.email'))
            .required(t('global.errors.required', { field: t('register.email') })),
          password: Yup.string()
            .required(t('global.errors.required', { field: t('register.password') })),
          confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], t('register.confirmPassword.error'))
            .required(t('global.errors.required', { field: t('register.confirmPassword.label') })),
          // phoneNumber: Yup.string()
          //   .required(t('global.errors.required', { field: t('register.phone') })),
        }) }
        onSubmit={(values, { setSubmitting }) => {
          const data = {
            ...values,
            ...(values.functionalArea && values.functionalArea.id && { functionalAreaId: +values.functionalArea.id }),
            registrationToken: values.registrationToken.trim().toUpperCase(),
            firstName: removeExtraSpaces(values.firstName),
            lastName: `${ removeExtraSpaces(values.lastName) } ${ removeExtraSpaces(values.secondSurname) }`
          };
          postRegister(data).then(() => {
            const loginState = store.getState().login;
            if (!loginState.registerLoading && !loginState.registerError) {
              localStorage.setItem('username', loginState.register.username);
              setUsername(loginState.register.username);
              setModalOpen(true);
              setSubmitting(false);
            } else {
              handleEndpointErrors(loginState, props, setSnackbar, t, values.registrationToken);
              setSubmitting(false);
            }
          });
        }}
      >
        {({ isSubmitting }) => (
          <CustomFormikForm>
            <Grid container spacing={ 3 }>
              <Grid item xs={ 12 } sm={ 6 } md={ 4 }><Field
                component={ TextField }
                name="registrationToken"
                label={ t('register.registrationToken') }
                variant="outlined"
                className={ classes.token }
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <VpnKeyIcon color="primary" />
                    </InputAdornment>
                  ),
                  onBlur: (event) => {
                    const value = event.target.value;
                    if ( value.length >= 3 ) {
                      const token = event.target.value.toUpperCase();
                      getFunctionalAreas(token).then(() => {
                        const loginState = store.getState().login;
                        if (!loginState.loading && !loginState.error) {
                          // console.log(loginState);
                        } else {
                          handleEndpointErrors(loginState, props, setSnackbar, t);
                        }
                      });
                    }
                  }
                }}
              /></Grid>
              <Grid item xs={ 12 } sm={ 6 } md={ 4 }><Field
                component={ TextField }
                name="firstName"
                label={ t('register.firstName') }
                variant="outlined"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PersonIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
              /></Grid>
              <Grid item xs={ 12 } sm={ 6 } md={ 4 }><Field
                component={ TextField }
                name="lastName"
                label={ t('register.lastName') }
                variant="outlined"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PersonIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
              /></Grid>
              <Grid item xs={ 12 } sm={ 6 } md={ 4 }><Field
                component={ TextField }
                name="secondSurname"
                label={ t('register.secondSurname') }
                variant="outlined"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PersonIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
              /></Grid>
              { !!functionalAreas.length && <Grid item xs={ 12 } md={ 6 }><Field
                component={ FormikAutocomplete }
                name="functionalArea"
                disableClearable
                options={ functionalAreas }
                getOptionLabel={ option => option ? option.name : '' }
                textFieldProps={{ label: t('register.functionalArea'), fullWidth: true, variant: 'outlined', icon: <WorkIcon color="primary" /> }}
                disabled={ !functionalAreas.length }
              /></Grid> }
              <Grid item xs={ 12 } sm={ 6 } md={ 4 }><Field
                component={ TextField }
                type="email"
                name="email"
                label={ t('register.email') }
                variant="outlined"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <MailOutlineIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
              /></Grid>
              <Grid item xs={ 12 } sm={ 6 } md={ 4 }><Field
                component={ TextField }
                type="password"
                name="password"
                label={ t('register.password') }
                variant="outlined"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <LockOutlinedIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
              /></Grid>
              <Grid item xs={ 12 } sm={ 6 } md={ 4 }><Field
                component={ TextField }
                type="password"
                name="confirmPassword"
                label={ t('register.confirmPassword.label') }
                variant="outlined"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <LockOutlinedIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
              /></Grid>
              <Grid item xs={ 12 } sm={ 6 } md={ 4 }><Field
                component={ TextField }
                name="phoneNumber"
                label={`${  t('register.phone') } (${ t('register.optional') })`}
                variant="outlined"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PhoneIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
              /></Grid>
            </Grid>
            <div className={ classes.btnWrapper }>
              <Button
                className={ classes.btnSubmit }
                color="primary"
                variant="contained"
                type="submit"
                disabled={ isSubmitting }
              >{ t('register.button') }</Button>
              { registerLoading && <CircularProgress size={ 24 } className={ classes.btnProgress } /> }
            </div>
          </CustomFormikForm>
        )}
      </Formik>
      <Dialog onClose={ handleModalClose } open={ modalOpen } fullWidth maxWidth="sm">
        <DialogTitle className={ classes.modalTitle }>{ t('register.modal.title') } <CheckIcon /></DialogTitle>
        <DialogContent>
          { t('register.modal.content') } <strong>{ username }</strong>
        </DialogContent>
        <DialogActions>
          <Button onClick={ handleModalClose } color="primary">
            { t('register.modal.button') }
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

Register.propTypes = {
  login: PropTypes.func.isRequired,
};

export default withRouter(Register);
