import React from 'react';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import AddLocationIcon from '@material-ui/icons/AddLocation';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import DescriptionIcon from '@material-ui/icons/Description';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import Snackbar from '@material-ui/core/Snackbar';

import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';

import ReactMapGL, { Marker, NavigationControl, FlyToInterpolator } from 'react-map-gl';

import * as Sentry from '@sentry/browser';

import FormikAutocomplete from '../components/FormikAutocomplete';

import { handleEndpointErrors, SNACKBAR_TIME } from '../shared/utilities';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  mapContainer: {
    height: '38vh',
    position: 'relative',
    width: '100vw',
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      height: '50vh',
    },
    '& .mapboxgl-ctrl-logo, & .mapboxgl-ctrl-bottom-right': {
      display: 'none',
    },
  },
  pinNoteMsg: {
    position: 'absolute',
    top: theme.spacing(1),
    zIndex: 1,
    '& .MuiSnackbarContent-root, & .MuiIconButton-root': {
      color: theme.palette.common.white,
    },
    '& .MuiSnackbarContent-root': {
      backgroundColor: theme.palette.primary.dark,
      flexWrap: 'nowrap',
    },
  },
  navigation: {
    bottom: '33px',
    position: 'absolute',
    right: '17px',
    zIndex: 3,
    '& > div': {
      borderRadius: '16px',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    },
    '& .mapboxgl-ctrl-icon:focus': {
      boxShadow: 'none',
    },
  },
  marker: {
    color: theme.palette.secondary.dark,
  },
  absoluteIcon: {
    backgroundColor: theme.palette.common.white,
    borderRadius:' 50%',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    color: theme.palette.common.black,
    cursor: 'pointer',
    fontSize: '32px',
    padding: '6px',
    position: 'absolute',
    right: '16px',
    zIndex: 3,
  },
  locateUser: {
    bottom: '98px',
  },
  locateUserLoading: {
    '& path': {
      animation: `$blinker 1s linear infinite`,
    },
  },
  form: {
    backgroundColor: theme.palette.background.default,
    borderRadius: '12px',
    padding: theme.spacing(4, 2),
    position: 'relative',
    top: '-8px',
    '& .MuiOutlinedInput-root': {
      borderRadius: '12px',
      '&:not(.Mui-error)': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.divider,
        },
        '& .MuiInputAdornment-root': {
          color: theme.palette.primary.light
        },
        '&:not(.Mui-disabled) .MuiInputAdornment-root': {
          color: theme.palette.primary.main
        },
      },
    },
  },
  input: {
    display: 'block',
    marginBottom: theme.spacing(4),
  },
  btnSubmit: {
    display: 'flex',
    margin: `${ theme.spacing(4) }px auto`,
    minWidth: '200px',
    borderRadius: '24px',
  },
  '@keyframes blinker': {
    '50%': {
      opacity: 0,
    },
  },
}));

const Location = (props) => {
  const classes = useStyles();

  const store = useStore();

  // const theme = useStoreState(state => state.global.theme);
  const storeMenuTitle = useStoreActions(actions => actions.global.storeTitle);
  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const getPlace = useStoreActions(actions => actions.places.getPlace);
  const postPlace = useStoreActions(actions => actions.places.postPlace);
  const updatePlace = useStoreActions(actions => actions.places.updatePlace);

  const provinces = useStoreState(state => state.countries.provinces);
  const counties = useStoreState(state => state.countries.counties);
  const getProvinces = useStoreActions(actions => actions.countries.getProvinces);
  const getCounties = useStoreActions(actions => actions.countries.getCounties);

  const [isAdd, setIsAdd] = React.useState(null);
  const [showPinMsg, setShowPinMsg] = React.useState(true);
  const [isLocatingUser, setIsLocatingUser] = React.useState(false);

  const t = useTranslation();

  React.useEffect(() => {
    getProvinces().then(() => {
      const countriesState = store.getState().countries;
      if (!countriesState.loading && !countriesState.error) {
        // console.log('success', countriesState);
      } else {
        handleEndpointErrors(countriesState, props, setSnackbar, t);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (props.match.params.id === undefined) {
      setIsAdd(true);
      storeMenuTitle(t('location.addPageTitle'));
    } else {
      getPlace(props.match.params.id).then(() => {
        const placesState = store.getState().places;
        if (!placesState.loading && !placesState.error) {
          setIsAdd(false);
          const place = placesState.currentItem;
          setViewport({ ...viewport, latitude: parseFloat(place.latitude), longitude: parseFloat(place.longitude) });
          setMarker({ latitude: parseFloat(place.latitude), longitude: parseFloat(place.longitude) });
          storeMenuTitle(t('location.editPageTitle'));

          getCounties(place.province.id).then(() => {
            const countriesState = store.getState().countries;
            if (!countriesState.loading && !countriesState.error) {
              formRef.current.setFieldValue('province', place.province, false);
              formRef.current.setFieldValue('county', place.county, false);
              formRef.current.setFieldValue('description', place.description, false);
              // console.log('success', countriesState);
            } else {
              handleEndpointErrors(countriesState, props, setSnackbar, t);
            }
          });
          // console.log('success', placesState);
        } else {
          handleEndpointErrors(placesState, props, setSnackbar, t);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [marker, setMarker] = React.useState({
    latitude: 9.9360366,
    longitude: -84.1007609,
  });

  const [viewport, setViewport] = React.useState({
    latitude: marker.latitude,
    longitude: marker.longitude,
    zoom: 11
  });

  const onLocateUserHandler = () => {
    if ("geolocation" in navigator) {
      setIsLocatingUser(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const newViewport = {
            ...viewport,
            latitude,
            longitude,
            zoom: 18,
            transitionDuration: 1000,
            transitionInterpolator: new FlyToInterpolator(),
          };
          onPinMsgClose();
          setIsLocatingUser(false);
          setMarker({ latitude, longitude });
          setViewport( newViewport );
        },
        (error) => {
          Sentry.captureException(error);
          console.error("Error fetching location:", error);
          setIsLocatingUser(false);
          setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.ERROR, severity: 'error', message: t('location.geolocation.error') });
        }
      );
    } else {
      setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.ERROR, severity: 'error', message: t('location.geolocation.notSupported') });
    }
  };

  const onMarkerDragEndHandler = event => {
    onPinMsgClose();
    setMarker({
      latitude: event.lngLat[1],
      longitude: event.lngLat[0]
    });
  };

  // eslint-disable-next-line array-callback-return
  const onFieldChangeHandler = (fields, setFieldValue, setFieldTouched) => fields.map(field => {
    setFieldValue(`${ field }`, '', false);
    setFieldTouched(`${ field }`, false);

    if (field === 'county') {
      setTimeout(() => {
        getCounties(formRef.current.values.province.id).then(() => {
          const countriesState = store.getState().countries;
          if (!countriesState.loading && !countriesState.error) {
            // console.log('success', countriesState);
          } else {
            handleEndpointErrors(countriesState, props, setSnackbar, t);
          }
        });
      });
    }
  });

  const onPinMsgClose = () => {
    setShowPinMsg(false);
  };

  const formRef = React.useRef();

  return (
    <React.Fragment>
      <div className={ classes.mapContainer }>
        <ReactMapGL
          { ...viewport }
          width="100%"
          height="100%"
          mapStyle="mapbox://styles/mapbox/streets-v11"
          onViewportChange={ setViewport }
          mapboxApiAccessToken={ process.env.REACT_APP_MAP_TOKEN }
        >
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={ showPinMsg }
            className={ classes.pinNoteMsg }
            message={ t('location.pinNoteMsg.message') }
            action={ <IconButton aria-label={ t('location.pinNoteMsg.close') } onClick={ onPinMsgClose }><CloseIcon /></IconButton> }
          ></Snackbar>
          <div className={ classes.navigation }>
            <NavigationControl showCompass={ false } />
          </div>
          <Marker
            className={ classes.marker }
            latitude={ marker.latitude }
            longitude={ marker.longitude }
            draggable
            onDragEnd={ onMarkerDragEndHandler }
          >
            <AddLocationIcon />
          </Marker>
        </ReactMapGL>
        <GpsFixedIcon className={ clsx(classes.absoluteIcon, classes.locateUser, { [classes.locateUserLoading]: isLocatingUser }) } onClick={ onLocateUserHandler } color="primary" />
      </div>
      <Formik
        innerRef={ formRef }
        initialValues={{ province: '', county: '', description: '' }}
        validate={values => {
          const errors = {};
          if ( !values.province ) {
            errors.province = t('location.province.errors.required');
          }
          if ( !values.county ) {
            errors.county = t('location.county.errors.required');
          }
          if ( !values.description ) {
            errors.description = t('location.description.errors.required');
          }
          if ( values.description.length >= 80 ) {
            errors.description = t('location.description.errors.max', { length: 80 });
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          const allValues = {
            ...values,
            ...(!isAdd && { id: props.match.params.id }),
            provinceId: values.province.id,
            countyId: values.county.id,
            latitude: marker.latitude,
            longitude: marker.longitude,
          }

          const submitFunc = isAdd ? postPlace : updatePlace;

          submitFunc(allValues).then(() => {
            const placesState = store.getState().places;
            if (!placesState.loading && !placesState.error) {
              setSubmitting(false);
              setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: isAdd ? t('locations.snackbar.created') : t('locations.snackbar.updated') });
              props.history.push({ pathname: '/locations' });
            } else {
              setSubmitting(false);
              handleEndpointErrors(placesState, props, setSnackbar, t);
            }
          });
        }}
      >
        {({ setFieldValue, setFieldTouched, values, submitForm, isSubmitting }) => (
          <Form className={ classes.form }>
            <Field
              component={ FormikAutocomplete }
              name="province"
              className={ classes.input }
              disabled={ isAdd === null }
              options={ provinces }
              // groupBy={ option => option.type }
              getOptionLabel={ option => option ? option.name : '' }
              textFieldProps={{ label: t('location.province.label'), fullWidth: true, variant: 'outlined', icon: <LocationCityIcon /> }}
              onChange={() => {
                onFieldChangeHandler(['county'], setFieldValue, setFieldTouched);
              }}
            />
            <Field
              component={ FormikAutocomplete }
              name="county"
              className={ classes.input }
              disabled={ isAdd === null || !values.province }
              options={ counties }
              // groupBy={ option => option.type }
              getOptionLabel={ option => option ? option.name : '' }
              textFieldProps={{ label: t('location.county.label'), fullWidth: true, variant: 'outlined', icon: <HomeWorkIcon /> }}
            />
            <Field
              component={ TextField }
              name="description"
              className={ classes.input }
              disabled={ isAdd === null }
              label={ t('location.description.label') }
              variant="outlined"
              autoComplete="off"
              multiline
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <DescriptionIcon />
                  </InputAdornment>
                ),
              }}
            />
            { isAdd !== null ? <div className={ classes.btnWrapper }>
              <Button
                className={ classes.btnSubmit }
                type="submit"
                disabled={ isSubmitting }
                onClick={ submitForm }
                variant="contained"
                color="primary"
              >{ isAdd ? t('location.addButton') : t('location.editButton') }</Button>
            </div> : null }
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
}

Location.propTypes = {
  logout: PropTypes.func.isRequired,
};

export default withRouter(Location);
