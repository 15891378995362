import React from 'react';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import Alert from '@material-ui/lab/Alert';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Fab from '@material-ui/core/Fab';

import CustomCard from '../components/CustomCard';
import LoadingCard from '../components/LoadingCard';

import { handleEndpointErrors } from '../shared/utilities';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 'calc(100vh - 56px)',
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    '& .MuiTypography-h6': {
      lineHeight: 1.2,
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: 'calc(100vh - 64px)',
    },
  },
  fab: {
    bottom: '24px',
    position: 'fixed',
    right: '24px',
  }
}));

const Locations = (props) => {
  const classes = useStyles();

  const store = useStore();

  const storeMenuTitle = useStoreActions(actions => actions.global.storeTitle);
  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const places = useStoreState(state => state.places.items);
  const placesLoading = useStoreState(state => state.places.loading);
  const placesLoadingCard = useStoreState(state => state.places.loadingCard);
  // const placesError = useStoreState(state => state.places.error);
  const getPlaces = useStoreActions(actions => actions.places.getPlaces);

  // const deleteUserPlace = useStoreActions(actions => actions.places.deleteUserPlace);
  // const deletePlace = useStoreActions(actions => actions.places.deletePlace);

  const canEdit = false;
  const isDisabled = places?.length >= 1;

  React.useEffect(() => {
    getPlaces().then(() => {
      const placesState = store.getState().places;
      if (!placesState.loading && !placesState.error) {
        // console.log(placesState.items);
      } else {
        handleEndpointErrors(placesState, props, setSnackbar, t);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const onDeleteHandler = (userPlaceId, placeId) => event  => {
  //   deleteUserPlace(userPlaceId).then(() => {
  //     const placesState = store.getState().places;
  //     if (!placesState.error) {
  //       // console.log('success', placesState);
  //       deletePlace(placeId).then(() => {
  //         const placesState = store.getState().places;
  //         if (!placesState.error) {
  //           // console.log('success', placesState);
  //         } else {
  //           handleEndpointErrors(placesState, props, setSnackbar, t);
  //         }
  //       });
  //     } else {
  //       handleEndpointErrors(placesState, props, setSnackbar, t);
  //     }
  //   });
  // }

  const onEditHandler = (placeId) => event  => {
    props.history.push({ pathname: `/locations/edit/${ placeId }` });
  }

  const onAddHandler = () => {
    props.history.push({ pathname: '/locations/add' });
  }

  const t = useTranslation();

  storeMenuTitle(t('locations.pageTitle'));

  return (
    <div className={ classes.root }>
      { placesLoading ? <LoadingCard length={ 5 } height={ 177 } /> :
        places.length ? places.map(place => (
          placesLoadingCard === place.id ?
            <LoadingCard key={ place.id } length={ 1 } height={ 177 } /> :
            <CustomCard
              key={ place.id }
              title={ place.description }
              body={ `${ place.province.name } - ${ place.county.name }` }
              // primaryIconBtn={{
              //   icon: <DeleteOutlineIcon color="primary" />,
              //   handler: onDeleteHandler(place.userPlace.id, place.id),
              //   backText: t('locations.card.backCard.btnDelete')
              // }}
              secondaryBtn={canEdit ? {
                text: t('locations.card.editBtn'),
                handler: onEditHandler(place.id),
                backText: t('locations.card.backCard.btnCancel')
              } : null}
              backMsg={ t('locations.card.backCard.msgDelete') }
              backPaddingBottom="37px"
              backIcon={ <DeleteOutlineIcon /> }
            />
        )) : (
          <CustomCard type="notFound" notFoundMsg={ t('locations.card.notFound') } />
        )
      }

      { isDisabled && <Alert severity="info">{ canEdit ? t('locations.alertDisabled') : `${ t('locations.alertDisabled') } ${ t('locations.alertCannotEdit') }` }</Alert> }
      <Fab className={ classes.fab } color="primary" aria-label="add" onClick={ onAddHandler }  disabled={ placesLoading || isDisabled }>
        <AddIcon />
      </Fab>
    </div>
  );
}

Locations.propTypes = {
  logout: PropTypes.func.isRequired,
};

export default withRouter(Locations);
