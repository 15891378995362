import * as Sentry from '@sentry/browser';
import LogRocket from 'logrocket';

export const SNACKBAR_TIME = {
  SUCCESS: 3000,
  INFO: 4000,
  ERROR: 5000,
};
export const DEFAULT_SNACKBAR = { show: false, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: '' };

// Favorite Routes
export const FR_DAYS_ALL = [1, 2, 3, 4, 5, 6, 0];
export const FR_DAYS = [1, 6, 0];
export const FR_WEEKDAYS = [1, 2, 3, 4, 5];

const clients = ['sertuva'];
export const WHITE_LABEL = clients.find(client => window.location.host.includes(client)) || 'app';

export const IS_STAGE = window.location.host.startsWith('stage.');

export const ERRORS = {
  DEVICE_NOT_FOUND: 'NotFoundError: Requested device not found',
  PERMISSION_DENIED: 'NotAllowedError: Permission denied',
  PERMISSION_DISMISSED: 'NotAllowedError: Permission dismissed',
};

export const updateManifestAndIcons = () => {
  const manifest = document.querySelector('link[rel="manifest"]');
  if (manifest) {
    manifest.href = `${ process.env.PUBLIC_URL }/${ WHITE_LABEL }/manifest.json`;
  }

  const icon = document.querySelector('link[rel="icon"]');
  if (icon) {
    icon.href = `${ process.env.PUBLIC_URL }/${ WHITE_LABEL }/favicon.ico`;
  }

  const appleTouchIcon = document.querySelector('link[rel="apple-touch-icon"]');
  if (appleTouchIcon) {
    appleTouchIcon.href = `${ process.env.PUBLIC_URL }/${ WHITE_LABEL }/logo192.png`;
  }
};

/**
 * RegEx for password
 *   6 characters minimum &
 *   50 character maximum &
 *   At least:
 *     One lowercase/uppercase character &
 *     One number
 */
 export const PASSWORD_REGEX = /^(?=.*[a-zA-Z])(?=.*\d)[A-Za-z\d@$!%*?&_]{6,50}$/;

/**
 * Remove time zone from date string
 * @param {string} date - The date that needs to be updated.
 */
export const transformDate = date => date.split('T')[0].replace(/-/g, '/');

/**
 * Remove time zone from date string and create a more readable date
 * @param {string} date - The date that needs to be updated.
 * @param {function} t - The translation function.
 */
export const formatDate = (date, t) => {
  let fullDate = new Date( transformDate(date) );
  return `${ t(`global.months.${ fullDate.getMonth() }`) } ${ fullDate.getDate() }, ${ fullDate.getFullYear() }`;
};

/**
 * Update time string and create a more readable time
 * @param {string} time - The time that needs to be updated.
 */
export const formatTime = (time) => {
  const formatTime = time.split(':');
  let hours = formatTime[0];
  let minutes = formatTime[1];
  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12 || 12;

  return `${ hours }:${ minutes } ${ ampm }`;
};

/**
 * Sort by time string
 * @param {string} times - The times array
 * @param {string} property - If it needs a sort property from the array
 */
export const sortByTime = (times, property) => {
  return times ? times.sort((a, b) => property ? a[property].localeCompare(b[property]) : a.localeCompare(b)) : [];
};

/**
 * Sort number element.
 * @param a , it could be an number | object
 * @param b , it could be an number | object
 * @param key, it is the property that we want to use when "a" & "b" are objects
 */
export function sortByNumberItems(a, b, key, order = 'asc') {
  const x = key ? parseInt(a[key], 10) : a;
  const y = key ? parseInt(b[key], 10) : b;
  if (order === 'asc') {
    return x < y ? -1 : x > y ? 1 : 0;
  }

  return x > y ? -1 : x < y ? 1 : 0;
}

/**
 * The function `getFavoriteRoutesDaysByDirection` extracts and organizes favorite route days by
 * direction from the input data.
 * @param data - The `getFavoriteRoutesDaysByDirection` function takes an array of data as input. Each
 * element in the array represents a favorite route with information about its direction and the
 * weekdays it operates on.
 */
export const getFavoriteRoutesDaysByDirection = (data, allowedDays) => {
  const dirIn = data?.filter(fr => fr.route.direction === 'in')?.map(fr => fr.weekDays)?.flat() || [];
  const dirOut = data?.filter(fr => fr.route.direction === 'out')?.map(fr => fr.weekDays)?.flat() || [];
  const isInComplete = dirIn.length === allowedDays.length;
  const isOutComplete = dirOut.length === allowedDays.length;
  const isComplete = isInComplete && isOutComplete;
  return { in: dirIn, out: dirOut, isInComplete,isOutComplete, isComplete };
};

/**
 * The `removeExtraSpaces` function takes a string as input, trims any leading or trailing spaces, and
 * replaces multiple consecutive spaces with a single space.
 * @param str - A string that may contain extra spaces that need to be removed.
 * @returns The `removeExtraSpaces` function is being returned.
 */
export const removeExtraSpaces = (str) => {
  return str.trim().replace(/\s+/g, ' ');
}

const addLogRocket = !!process.env.REACT_APP_LOGROCKET && JSON.parse(localStorage.getItem('isLogRocketUser')) && !!localStorage.getItem('id');
export const initializeLogRocket = () => {
  if ( addLogRocket ) {
    LogRocket.init(process.env.REACT_APP_LOGROCKET);
    identifyLogRocketUser();
  }
}
export const identifyLogRocketUser = () => {
  LogRocket.identify(localStorage.getItem('id'), {
    name: localStorage.getItem('name'),
    email: localStorage.getItem('email'),
    username: localStorage.getItem('username'),
    token: localStorage.getItem('token'),
    countryId: localStorage.getItem('countryId'),
  });
}

/**
 * Handle endpoint errors
 * @param {Object} state - The current state that you want to check.
 * @param {Object} props - The props of the page.
 * @param {function} setSnackbar - The action to call the snackbar component.
 * @param {function} t - The translation function.
 * @param {string} entity - Optional: The entity of the current action to be used on some messages
 */
export const handleEndpointErrors = (state, props, setSnackbar, t, entity) => {
  if (state.error.status === 401) {
    props.logout(true, true);
  } else if (state.error.status === 403) {
    if (state.error.data?.errors?.length && state.error.data.errors[0].includes('not allowed to make a reservation')) {
      setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.ERROR, severity: 'error', message: t('global.errors.endpoint.403') });
    }
  } else if (state.error.status === 404) {
    if (entity) {
      setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.ERROR, severity: 'error', message: t('global.errors.endpoint.404withEntity', { entity }) });
    } else {
      setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.ERROR, severity: 'error', message: t('global.errors.endpoint.404withoutEntity') });
    }
  } else if (state.error.status === 420) {
    if (entity) {
      setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.ERROR, severity: 'error', message: t('global.errors.endpoint.420withEntity', { entity }) });
    } else {
      setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.ERROR, severity: 'error', message: t('global.errors.endpoint.420withoutEntity') });
    }
  } else if (state.error.status === 450) {
    setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.ERROR, severity: 'error', message: t('global.errors.endpoint.450') })
  } else if (state.error.status === 460 && (entity === 'create' || entity === 'cancel')) {
    if (entity === 'create') {
      setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.ERROR, severity: 'error', message: t('global.errors.endpoint.460.create') })
    } else if (entity === 'cancel') {
      setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.ERROR, severity: 'error', message: t('global.errors.endpoint.460.cancel', { hours: localStorage.getItem('clientRsvCancelHours') }) });
    }
  } else if (typeof state.registerError?.data === 'string' && state.registerError.data.includes('Invalid registration token')) {
    setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.ERROR, severity: 'error', message: t('global.errors.endpoint.invalidToken', { token: entity }) });
  } else if (state.changePasswordError?.status === 422) {
    setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.ERROR, severity: 'error', message: t('global.errors.endpoint.incorrectPreviousPassword') });
  } else if (state.error.status === 421 && state.error?.data?.errors?.length && state.error.data.errors[0].includes('reservation')) {
    setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.ERROR, severity: 'error', message: t('home.error421') })
  } else if (state.error.status === 423 && state.error?.data?.errors?.length && state.error.data.errors[0].includes('auto-create')) {
    setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.ERROR, severity: 'error', message: t('home.error423') })
  } else if (state.error.status === 423 && state.error?.data?.errors?.length && state.error.data.errors[0].includes('QR Code')) {
    setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.ERROR, severity: 'error', message: t('global.errors.endpoint.423') })
  } else {
    setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.ERROR, severity: 'error', message: t('global.errors.endpoint.default') });
  }
}

/**
 * Log Sentry endpoint error
 * @param {Object} error - Error that occurred.
 */
export const logSentryEndpointError = error => {
  if (error.response && ![401, 404, 420, 421, 422, 423, 460].includes(error.response.status)) {
    Sentry.withScope(function(scope) {
      scope.setTag('request', `${ error.response.config.url }`);
      scope.setTag('method', `${ error.config.method }`);
      Sentry.setContext('Request Information', {
        'URL': error.response.config.url,
        'Method': error.config.method,
        'Status': error.request.status,
        'Status Text': error.request.statusText,
        'Data': error.config.data,
        'Error': error.request.response,
      });
      Sentry.captureException(error);
    });
  }
}
